/* eslint-disable no-nested-ternary */
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { currencySymbol } from '@/utils/currencies';
import { ChartDot } from '@/features/details/charts/chart';
import { Card } from '@/components/card';
import { isBrowser } from '@/utils/browser';
import { ReportingDataContext } from '@/features/dashboard/reports_v2/context';
import { HSpace, VSpace } from '@/components/spacing';
import { IconButton } from '@/features/dashboard/reports_v2/common';
import { MinusSvg } from '@/features/dashboard/reports_v2/icons/minusSvg';
import { PlusSvg } from '@/features/dashboard/reports_v2/icons/plusSvg';
import { DailyMetrics } from '@/features/dashboard/reports_v2/models';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Inter, serif;
`;

function chartWidth() {
  return (
    isBrowser() &&
    Math.max(window.innerWidth * (window.innerWidth <= 1300 ? 0.77 : 0.5), 1000)
  );
}
enum ChartMetricKind {
  TOTAL_REVENUE = `TOTAL_REVENUE`,
  DIRECT_REVENUE = `DIRECT_REVENUE`,
  VIA_EXP_REVENUE = `VIA_EXP_REVENUE`,
}

export const DailySummaryChart: FC = () => {
  const { dailySummary, net } = useContext(ReportingDataContext);
  const width = chartWidth();
  const data = transform(dailySummary, net);
  const [key, setKey] = useState(ChartMetricKind.TOTAL_REVENUE);
  const [open, setOpen] = useState(true);
  const stroke = getStorkeColour(key);
  return (
    <DetailsCard open={open}>
      <Container>
        <FlexRow>
          <Title>By Day</Title>
          <Flex>
            <TypeMenu open={open}>
              <TypeMenuButton
                enabled={key === ChartMetricKind.TOTAL_REVENUE}
                onClick={() => setKey(ChartMetricKind.TOTAL_REVENUE)}
              >
                Total Revenue
              </TypeMenuButton>
              <TypeMenuButton
                enabled={key === ChartMetricKind.VIA_EXP_REVENUE}
                onClick={() => setKey(ChartMetricKind.VIA_EXP_REVENUE)}
              >
                Revenue Through Experiences
              </TypeMenuButton>
              <TypeMenuButton
                enabled={key === ChartMetricKind.DIRECT_REVENUE}
                onClick={() => setKey(ChartMetricKind.DIRECT_REVENUE)}
              >
                Direct Revenue
              </TypeMenuButton>
            </TypeMenu>
            <HSpace />
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <MinusSvg /> : <PlusSvg />}
            </IconButton>
          </Flex>
        </FlexRow>
        {open && (
          <>
            <VSpace value={6} />
            <LineChart width={width} height={300}>
              <Tooltip
                formatter={formatter()}
                labelStyle={{ color: `white`, fontSize: `1.3rem` }}
                itemStyle={{
                  color: `white`,
                  fontSize: `1.2rem`,
                  fontFamily: `Inter`,
                }}
                contentStyle={{
                  background: `#4D4D4D`,
                  borderRadius: `1rem`,
                  border: `none`,
                }}
                wrapperStyle={{
                  boxShadow: `0 5px 7px 0 rgba(26,47,116,0.22)`,
                }}
              />
              <XAxis
                dataKey="date"
                type="category"
                allowDuplicatedCategory={false}
              />
              <YAxis dataKey={key} type="number" tickFormatter={formatter()} />
              <CartesianGrid stroke="#e7ebed" />
              <Line
                isAnimationActive={false}
                dataKey={key}
                key={key}
                name={key}
                data={data}
                legendType="circle"
                stroke={stroke}
                strokeWidth={2}
                dot={<ChartDot />}
              />
            </LineChart>
          </>
        )}
      </Container>
    </DetailsCard>
  );
};

function formatter() {
  const symbol = currencySymbol();
  return (tick) => `${symbol}${tick?.toLocaleString()}`;
}

const DetailsCard = styled(Card)`
  && {
    width: 100%;
    cursor: default;
    padding: ${(p) => (p.open ? 3.5 : 1)} rem;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
  }
`;
const TypeMenu = styled.div<{ open: boolean }>`
  background-color: #f2f2f2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  opacity: ${(p) => (p.open ? 1 : 0)};
`;
const Flex = styled.div`
  display: flex;
`;
const FlexRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const TypeMenuButton = styled.button<{ enabled: boolean }>`
  font-weight: 600;
  background-color: ${(p) => (p.enabled ? `#ffffff` : `#f2f2f2`)};
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: ${(p) =>
    p.enabled
      ? `0 1px 1px 0 rgba(0,0,0,0.22), 0 5px 32px 0 rgba(182,207,205,0.47)`
      : `none`};

  &:hover {
    cursor: pointer;
  }
`;
function transform(storeSummary: DailyMetrics, net: boolean) {
  return storeSummary?.metrics?.map((m) => {
    const date = new Date(m.day)
      .toLocaleDateString()
      ?.split(`/`)
      ?.reverse()
      ?.join(`-`);
    return {
      date,
      [ChartMetricKind.TOTAL_REVENUE]:
        (net ? m.netTotalRevenue : m.totalRevenue) * 1 || 0,
      [ChartMetricKind.DIRECT_REVENUE]:
        (net ? m.netDirectRevenue : m.directRevenue) * 1 || 0,
      [ChartMetricKind.VIA_EXP_REVENUE]:
        (net ? m.netViaExpRevenue : m.viaExpRevenue) * 1 || 0,
    };
  });
}
const Title = styled.div<{ open: boolean }>`
  text-align: start;
  font-size: 1.5rem;
  font-family: Inter, serif;
  font-weight: 700;
  padding-bottom: ${(p) => (p.open ? 1 : 0)}em;
  border-bottom: ${(p) => (p.open ? 1 : 0)}px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const getStorkeColour = (key: ChartMetricKind) => {
  let stroke = `#7209b7`;
  if (key === ChartMetricKind.DIRECT_REVENUE) {
    stroke = `#f72585`;
  } else if (key === ChartMetricKind.VIA_EXP_REVENUE) {
    stroke = `#4cc9f0`;
  }
  return stroke;
};
