import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { currencySymbol } from '@/utils/currencies';
import { ReportingDataContext } from '@/features/dashboard/reports_v2/context';
import { formatNumber } from '@/features/dashboard/reports_v2/common';
import { maybe } from '@/features/details/utils';
import { Uplift } from './uplift-badge';
import { StoreSummary } from '@/features/dashboard/reports_v2/models';
import { NoDataMsg } from '@/features/dashboard/reports_v2/NoData';
import { MetricsGrid } from '@/features/dashboard/reports_v2/top_section/shared';

export function DirectMetrics() {
  const symbol = currencySymbol();
  const { storeSummary: data } = useContext(ReportingDataContext);
  if (data == null || data?.SessionsWithUpsells === 0) {
    return <NoDataMsg />;
  }
  const {
    aov,
    aovNoVisually,
    sessionsUplift,
    ordersUplift,
    addedProductsUplift,
    aovUplift,
    revUplift,
  } = calcDirectStats(data);
  return (
    <MetricsGrid>
      <Title>
        Sessions with
        <br /> visually upsell
      </Title>
      <Title>
        Orders with <br />
        visually added products
      </Title>
      <Title>
        Visually added <br />
        products
      </Title>
      <Title>
        AOV
        <br />
        (without visually)
      </Title>
      <Title>
        Added
        <br /> Revenue
      </Title>
      <Value>{formatNumber(data?.SessionsWithUpsells)}</Value>
      <Value>{formatNumber(data?.DirectOrders)}</Value>
      <Value>{formatNumber(data?.DirectProducts)}</Value>
      <Value>
        <StrikeThrough>
          {symbol}
          {formatNumber(aov)}
        </StrikeThrough>
        {symbol}
        {formatNumber(aovNoVisually)}
      </Value>
      <Value>
        {symbol}
        {formatNumber(data?.DirectRev)}
      </Value>
      <Uplift value={sessionsUplift} />
      <Uplift value={ordersUplift} />
      <Uplift value={addedProductsUplift} />
      <Uplift showSign value={aovUplift} suffix="with visually" />
      <Uplift value={revUplift} />
    </MetricsGrid>
  );
}
const Value = styled.p`
  color: black;
  font-size: 2rem;
  font-family: 'Inter', serif;
  font-weight: 700;
  margin: 0;
  padding: 0;
`;
const Title = styled.p`
  color: #a6afb8;
  font-weight: 800;
  font-size: 1.1rem;
  height: 3rem;
  margin: 0;
  padding: 0;
`;

const StrikeThrough = styled.span`
  text-decoration: line-through;
  color: #999999;
  margin-right: 0.5rem;
`;

const calcDirectStats = (data: StoreSummary) => {
  const aov = maybe(
    () => Math.round((data!.TotalRevenue / data!.TotalOrders) * 100) / 100,
  );
  const aovNoVisually = maybe(
    () =>
      Math.round(
        ((data!.TotalRevenue - data.DirectRev) / data!.TotalOrders) * 100,
      ) / 100,
  );
  let sessionsUplift = (data.SessionsWithUpsells / data.TotalSessions) * 100;
  if (data.TotalSessions === 0) {
    sessionsUplift = null;
  }
  let ordersUplift = (data.DirectOrders / data.TotalOrders) * 100;
  if (data.TotalOrders === 0) {
    ordersUplift = null;
  }
  let addedProductsUplift = (data.DirectProducts / data.TotalItemsSold) * 100;
  if (data.TotalItemsSold === 0) {
    addedProductsUplift = null;
  }
  const aovUplift = 100 * (1 - aovNoVisually / aov);
  let revUplift = (data.DirectRev / data.TotalRevenue) * 100;
  if (data?.TotalRevenue === 0) {
    revUplift = null;
  }
  return {
    aov,
    aovNoVisually,
    sessionsUplift,
    ordersUplift,
    addedProductsUplift,
    aovUplift,
    revUplift,
  };
};
