/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Metric = styled.div`
  display: flex;
  flex-direction: column;

  p {
    padding: 0;
    margin: 0 0 1rem 0;
  }

  p:first-child {
    color: #a6afb8;
    font-weight: 800;
    font-size: 1.1rem;
    height: 3rem;
  }

  p:nth-child(2) {
    color: black;
    font-size: 2rem;
    font-family: 'Inter', serif;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
`;

export function getQueryDate(name: string) {
  try {
    return new Date(new URL(window.location.href).searchParams.get(name));
  } catch {
    return new Date();
  }
}

export function formatNumber(number: number) {
  if (typeof number !== `number` || Number.isNaN(number)) {
    return `0`;
  }

  if (Math.abs(number) >= 1000000) {
    const millions = number / 1000000;
    return `${millions % 1 === 0 ? millions : millions.toFixed(1)}M`;
  }
  if (Math.abs(number) >= 1000) {
    const thousands = number / 1000;
    return `${thousands % 1 === 0 ? thousands : thousands.toFixed(1)}K`;
  }
  return number.toString();
}

export const IconButton = styled.button`
  margin: 0 0.5rem;
  ${(p) => (p.disabled ? `opacity: 0.5;` : `cursor: pointer;`)}
  border-radius: 50%;


  ${(p) =>
    p.disabled
      ? ``
      : `
  svg {
    &:hover {
    rect {
      fill: black;
    }
      g {
        opacity: 1;

      }
    }

    &:active {
      rect {
      fill: #b6bac5;
    }
      g {
        opacity: 0.5;

      }
    }
  }
        `}
}
`;
