/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import styled from 'styled-components';
import { nFormatter } from '@/utils/experience-utils';
// p.positive ? `#1ed58b` : p.neutral ? `#a3a3a3` : `#ff7f00`
type Prop = { color: string; hasSuffix?: boolean };

const Container = styled.div<Prop>`
  position: relative;
  ${(p) => (p.hasSuffix ? `max-width: 125px` : `width:50px`)};

  background-color: ${(p) => p.color || `#a3a3a3`};
  color: white;
  font-family: 'Inter', serif;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 8px;
  font-size: 11px;

  text-align: center;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09), 0 1px 4px 0 rgba(7, 6, 6, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TriangleTop = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  top: -15px;
  left: 14px;

  border-bottom: 10px solid ${(p: Prop) => p.color || `#a3a3a3`};
`;

type Props = {
  value?: number;
  color?: string;
  showSign?: boolean;
  suffix?: string;
};
export const Uplift: FC<Props> = ({ value, color, showSign, suffix }) => {
  if (value === 0) {
    return null;
  }
  const digits = Math.abs(value) > 100 ? 0 : 1;
  const sign = value >= 0 ? `+` : `-`;
  const isPositive = value >= 3;
  const isNeutral = value < 3 && value >= -3;
  const isNegative = value < -3;
  if (showSign && isPositive) {
    color = `#1ed58b`;
  } else if (showSign && isNeutral) {
    color = `#edc400`;
  } else if (showSign && isNegative) {
    color = `#ff7f00`;
  }
  return (
    <Container color={color} hasSuffix={!!suffix}>
      {showSign ? sign : ``}
      {nFormatter(Math.abs(value), digits)}% {suffix || ``}
      <TriangleTop color={color} />
    </Container>
  );
};
