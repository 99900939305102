import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { VSpace } from '@/components/spacing';
import { GeneralMetrics } from '@/features/dashboard/reports_v2/top_section/general_metrics';
import { DirectMetrics } from '@/features/dashboard/reports_v2/top_section/direct_metrics';
import { RevThroughExpMetrics } from '@/features/dashboard/reports_v2/top_section/rev_through_exp_metrics';
import { MinusSvg } from '../icons/minusSvg';
import { PlusSvg } from '@/features/dashboard/reports_v2/icons/plusSvg';

import { IconButton } from '@/features/dashboard/reports_v2/common';

export function SummaryCards() {
  const [generalOpen, setGeneralOpen] = useState(true);
  const [directOpen, setDirectOpen] = useState(true);
  const [viaExpOpen, setViaExpOpen] = useState(true);
  return (
    <>
      <StoreSummaryCard>
        <Title open={generalOpen}>
          <span>General</span>
          <IconButton onClick={() => setGeneralOpen(!generalOpen)}>
            {generalOpen ? <MinusSvg /> : <PlusSvg />}
          </IconButton>
        </Title>
        {generalOpen && <GeneralMetrics />}
      </StoreSummaryCard>

      <VSpace value={3} />
      <StoreSummaryCard>
        <Title open={directOpen}>
          <span>Direct Sales</span>
          <IconButton onClick={() => setDirectOpen(!directOpen)}>
            {directOpen ? <MinusSvg /> : <PlusSvg />}
          </IconButton>
        </Title>
        {directOpen && <DirectMetrics />}
      </StoreSummaryCard>

      <VSpace value={3} />
      <StoreSummaryCard>
        <Title open={viaExpOpen}>
          <span>Revenue through Experience</span>
          <IconButton onClick={() => setViaExpOpen(!viaExpOpen)}>
            {viaExpOpen ? <MinusSvg /> : <PlusSvg />}
          </IconButton>
        </Title>
        {viaExpOpen && <RevThroughExpMetrics />}
      </StoreSummaryCard>
    </>
  );
}
const Title = styled.div<{ open: boolean }>`
  width: 100%;
  text-align: start;
  font-size: 1.5rem;
  font-family: Inter, serif;
  font-weight: 700;
  padding-bottom: ${(p) => (p.open ? 1 : 0)}em;
  border-bottom: ${(p) => (p.open ? 1 : 0)}px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StoreSummaryCard = styled.div`
  background-color: #ffffff;
  padding: 2rem 3rem;
  border: solid 1px #eaeef0;
  border-radius: 15px;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15),
    0 14px 36px 0 rgba(90, 201, 172, 0.12), 0 4px 42px 0 rgba(0, 0, 0, 0);

  display: flex;
  flex-direction: column;

  cursor: default;
  font-family: 'Inter', serif;
  transition: background-color ease-out 0.3s;
  user-select: none;
`;
